<template>
  <div>
    <app-notification :title="title"
                      :text="text"
                      :notification-buttons="notificationButtons"
                      :footer-button="footerButton"
                      :icon-type="'danger'"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Intercom from '@/Intercom';
import { mapState, mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import ConsentActionsMixin from '@/base-components/onboarding/openBanking/ConsentActionsMixin.vue';
import MigrateToOBKFlow from '@/base-components/credentials/credentials-list/MigrateToOBKFlow.vue';
import router from '@/router';
import Segment from '../../../../../../../Segment';
import AppNotification from '../AppNotification.vue';

export default {
  name: 'OutOfDateNotification',
  components: {
    AppNotification,
  },
  async created() {
    Segment.trackUserGot('OutOfDateNotificationShown', {
      closedBankingMigration: !!this.closedBankingFailedSource,
      closedBankingFailedSourceName: this.closedBankingFailedSourceName,
    });
    Segment.trackUserInteraction('JustInTimeCX_Shown', {
      source: this.$options.name,
      isButtonShown: this.closedBankingFailedSource || (!this.isIncidentOBKSource && this.jitTechnicalError),
    });
  },
  mixins: [ConsentActionsMixin],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('credentials', ['credentialsList', 'obkSourceConfigurations']),
    ...mapGetters('featureFlags', ['jitTechnicalError']),
    footerButton() {
      if (this.isIncidentOBKSource || !this.jitTechnicalError) {
        return null;
      }
      return {
        title: 'לצ׳אט עם הצוות',
        variant: 'tertiary',
        color: BaseUI.LayoutConsts.COLOR_VARIANT.BLACK,
        action: this.handleIntercomClickAction,
        // eslint-disable-next-line global-require
        icon: require('@/assets/icons/chat_icon_teriatry.svg'),
        id: 'cx-just-in-time-tech-error-footer-button',
      };
    },
    title() {
      if (this.obkFailedSource) {
        return `הנתונים מחשבון ${this.obkFailedSource.indefiniteDisplayName} לא מתעדכנים.`;
      }
      if (this.closedBankingFailedSource) {
        return `הנתונים מ${this.closedBankingFailedSourceName} לא מתעדכנים בצורה סדירה.`;
      }
      return 'התזרים לא מעודכן בגלל תקלה טכנית.';
    },
    isIncidentOBKSource() {
      const failedSource = this.obkFailedSource;
      if (!failedSource) {
        return false;
      }
      return _.some(this.obkSourceConfigurations, {
        bankIdentifier: failedSource.id,
        enabled: false,
        type: 'enableConsentCreation',
      });
    },
    text() {
      if (this.isIncidentOBKSource) {
        return `אנחנו בקשר עם הצוות הטכני של ${this.obkFailedSource.displayName} כדי להחזיר את השירות כמה שיותר מהר.`;
      }
      if (this.obkFailedSource) {
        return `צריך לחדש את ההסכמה ל${this.obkFailedSource.indefiniteDisplayName} כדי שהתזרים יוכל להתעדכן.`;
      }
      if (this.closedBankingFailedSource) {
        return `הנתונים מ${this.closedBankingFailedSourceName} שמתקבלים על בסיס הסיסמא שלך לא מתעדכנים.
        ניתן להתחבר עם חיבור חדש וטוב יותר - חיבור ישיר.`;
      }
      return 'הצוות שלנו עובד כדי לתקן את הבעיה ולהחזיר את השירות כמה שיותר מהר.';
    },
    obkFailedSource() {
      return _.find(this.credentialsList, { status: 'obkSourceFailure' });
    },
    closedBankingFailedSource() {
      return _.find(this.credentialsList, { status: 'closedBankingFailure' });
    },
    closedBankingFailedSourceName() {
      const source = this.closedBankingFailedSource;
      if (!source) {
        return undefined;
      }
      const sourceName = source.displayName;
      return source.type === 'bank' ? `בנק ${sourceName}` : sourceName;
    },
    notificationButtons() {
      if (this.isIncidentOBKSource) {
        return [];
      }
      if (this.obkFailedSource) {
        return [{
          title: 'לחידוש ההסכמה',
          action: this.goToCredentialsSettings,
          id: 'cx-just-in-time-tech-error-reconnect-source-button',
          loading: this.loading,
        }];
      }
      if (this.closedBankingFailedSource) {
        return [{
          title: 'למעבר לחיבור ישיר',
          action: () => this.migrateToOBK(this.closedBankingFailedSource),
          loading: this.loading,
        }];
      }
      return [];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('credentials', ['deleteCredentials']),
    handleIntercomClickAction() {
      Segment.trackUserInteraction('JustInTimeCX_Clicked', { source: this.$options.name });
      Intercom.showNewMessage('');
    },
    goToCredentialsSettings() {
      Segment.trackUserInteraction('goToCredentialsSettingsClicked');
      router.push({ path: '/credentials' });
    },
    migrateToOBK(source) {
      this.openModal({
        component: MigrateToOBKFlow,
        props: {
          source,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
